<!--混凝土计量抽查记录-->
<template>
    <div class="calc-check-record" v-loading="formLoading">
        <h2 class="title">
            混凝土拌合物计量抽查记录表
        </h2>
        <div class="top-info">
            <label>{{ companyName }}</label>
            <label class="required"><el-input prefix-icon="iconfont iconbianji" placeholder="请输入编号" v-model="form.no"></el-input></label>
        </div>
        <div class="table-info">
            <table>
                <tr>
                    <th width="10%">
                        配合比编号
                    </th>
                    <td width="15%">
                        {{ form.formula_number }}
                    </td>
                    <th width="7%">
                        派车单
                    </th>
                    <td width="18.2%">
                        <el-select
                            v-model="form.pcb_number"
                            placeholder="请选择派车单"
                            @change="changePcb"
                            style="width: 2.2rem"
                        >
                            <el-option
                                v-for="item in form.pcb_infos"
                                :label="item.pcb_number"
                                :value="item.pcb_number"
                                :key="item.pcb_number"
                            ></el-option>
                        </el-select>
                    </td>
                    <th>
                        搅拌机编号
                    </th>
                    <td width="5%">
                        {{ form.scbt }}
                    </td>
                    <th width="5%">
                        盘次
                    </th>
                    <td width="5%">
                        <el-select
                            v-model="form.pc"
                            placeholder="请选择盘次"
                            @change="changePc"
                        >
                            <el-option
                                v-for="item in choosePcbInfo.pc_infos"
                                :label="item.pc"
                                :value="item.pc"
                                :key="item.pcb_number"
                            ></el-option>
                        </el-select>
                    </td>
                    <th>
                        混凝土生产日期
                    </th>
                    <td>
                        {{ (form.product_time||'').substr(0,10) }}
                    </td>
                </tr>
            </table>
            <div class="table-wrapper">
                <div class="left-table">
                    <table>
                        <tr>
                            <th colspan="6">
                                每盘用料称量计量
                            </th>
                        </tr>
                        <col style="width: 1.38rem;">
                        <col style="width: 1.26rem;">
                        <col style="width: 1.09rem;">
                        <col style="width: 1.23rem;">
                        <col style="width: 1.31rem;">
                        <!--                        <col style="width: 1.02rem;">-->
                        <tr>
                            <th class="col-merge">
                                <div class="title-split ">
                                    <span>评定</span>
                                    <span>原材料品种</span>
                                </div>
                            </th>
                            <th><span>每盘计量允许</span><span>偏差值(%)</span></th>
                            <th><span>每盘配比值</span><span>（kg）</span></th>
                            <th><span>每盘实测值</span><span>（kg）</span></th>
                            <th><span>每盘计量实测</span><span>偏差值（%）</span></th>
                            <th>
                                <span>评定</span>
                            </th>
                        </tr>
                    </table>
                    <div class="wrapper-list">
                        <table>
                            <col style="width: 1.38rem;">
                            <col style="width: 1.26rem;">
                            <col style="width: 1.09rem;">
                            <col style="width: 1.23rem;">
                            <col style="width: 1.31rem;">
                            <!-- <col style="width: 1.02rem;">-->
                            <tr v-for="item in form.plate_rates" :key="'t1'+item.name">
                                <td>{{ item.name }}</td>
                                <td>
                                    ± <input
                                        type="number"
                                        class="mini-input"
                                        @input="testResult(item)"
                                        v-model="item.rate"
                                        placeholder="请输入"
                                    >
                                </td>
                                <td>{{ item.val }}</td>
                                <td>{{ item.m_val }}</td>
                                <td>{{ item.m_rate }}</td>
                                <td>{{ item.eva }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
                <div class="right-table">
                    <table>
                        <tr>
                            <th colspan="6">
                                每一运输车用料称量计量
                            </th>
                        </tr>
                        <col style="width: 1.38rem;">
                        <col style="width: 1.26rem;">
                        <col style="width: 1.09rem;">
                        <col style="width: 1.23rem;">
                        <col style="width: 1.31rem;">
                        <col style="width: 1.02rem;">
                        <tr>
                            <th class="col-merge">
                                <div class="title-split ">
                                    <span>评定</span>
                                    <span>原材料品种</span>
                                </div>
                            </th>
                            <th><span>每车计量允许</span><span>偏差值(%)</span></th>
                            <th><span>每盘配比值</span><span>（kg）</span></th>
                            <th><span>每盘实测值</span><span>（kg）</span></th>
                            <th><span>每盘计量实测</span><span>偏差值（%）</span></th>
                            <th><span>评定</span><span>评定</span></th>
                        </tr>
                    </table>
                    <div class="wrapper-list">
                        <table>
                            <col style="width: 1.38rem;">
                            <col style="width: 1.26rem;">
                            <col style="width: 1.09rem;">
                            <col style="width: 1.23rem;">
                            <col style="width: 1.31rem;">
                            <!--<col style="width: 1.02rem;">-->
                            <tr v-for="(item,index) in form.car_rates" :key="'t2'+index">
                                <td>{{ item.name }}</td>
                                <td>
                                    ± <input
                                        type="number"
                                        class="mini-input"
                                        @input="testResult(item)"
                                        v-model="item.rate"
                                        placeholder="请输入"
                                    >
                                </td>
                                <td>{{ item.val }}</td>
                                <td>{{ item.m_val }}</td>
                                <td>{{ item.m_rate }}</td>
                                <td>{{ item.eva }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <table style="margin-top: -1px;">
                <col style="width: 1.38rem;">
                <col style="width: 1.26rem;">
                <col style="width: 1.09rem;">
                <col style="width: 1.23rem;">
                <col style="width: 1.31rem;">
                <col style="width: 1.02rem;">
                <col style="width: 1.38rem;">
                <tr>
                    <th>混凝土坍落度</th>
                    <td>{{ form.slump }}</td>
                    <td colspan="4" class="col-merge">
                        <table>
                            <tr>
                                <th>设计坍落度值(mm)</th>
                                <th class="required">
                                    实测坍落度值(mm)
                                </th>
                                <td style="width: 1.02rem">
                                    评定
                                </td>
                            </tr>
                            <tr>
                                <td>{{ form.slump_name }}</td>
                                <td>
                                    <input
                                        type="number"
                                        class="mini-input"
                                        @input="testResultMeasured"
                                        v-model="form.measured_slump"
                                        placeholder="请输入"
                                    >
                                </td>
                                <td style="width: 1.02rem">
                                    {{ form.measured_slump_eva }}
                                </td>
                            </tr>
                        </table>
                    </td>
                    <th rowspan="2">
                        施工部位
                    </th>
                    <td rowspan="2" colspan="5">
                        <div class="work-place">
                            <el-input
                                type="textarea"
                                placeholder="请输入施工部位"
                                :rows="4"
                                v-model="form.place_name"
                            >
                            </el-input>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th class="required">
                        砼强度实测值
                    </th>
                    <td colspan="5">
                        <div class="space-between">
                            <input
                                type="number"
                                class="mini-input mid-input"
                                v-model="form.measured_concrete"
                                placeholder="请输入"
                            >
                            <el-radio-group v-model="form.measured_concrete_eva">
                                <el-radio label="合格">
                                    合格
                                </el-radio>
                                <el-radio label="不合格">
                                    不合格
                                </el-radio>
                            </el-radio-group>
                        </div>
                    </td>
                </tr>
                <tr>
                    <th style="width: 9.5%">
                        备注
                    </th>
                    <td colspan="11">
                        <el-input class="icon-input" prefix-icon="iconfont iconbianji" v-model="form.remark"></el-input>
                    </td>
                </tr>
            </table>
        </div>
        <div class="bottom-info">
            <label>被抽班组：<el-input prefix-icon="iconfont iconbianji" placeholder="请输入被抽班组" v-model="form.class_name"></el-input></label>
            <label>抽查人：<span>{{ form.creator }}</span></label>
            <label class="required">抽查日期：<el-date-picker v-model="form.created"></el-date-picker></label>
        </div>
        <div class="bottom-button">
            <el-button type="primary" @click="saveData">
                保存
            </el-button>
            <el-button @click="handleClose">
                关闭
            </el-button>
        </div>
    </div>
</template>

<script>
import utils from '@/utils/util';
export default {
    name: 'calc-check-record',
    props: {
        extr: Object,
    },
    data() {
        return {
            formLoading: false,
            // 企业名称
            companyName: '',
            // 当前选择的派车单信息
            choosePcbInfo: {},
            form: {
                pcb_infos: [
                    {
                        pcb_number: '',
                        scbt: '',
                        formula_number: '',
                        mixstaion_name: '',
                        pc_infos: [
                            {
                                pc: 0,
                                consumes_id: '',
                                product_time: '',
                            },
                        ],
                    },
                ],
                cir_id: '',
                consumes_id: '',
                task_number: '',
                pcb_number: '',
                mixstation_code: '',
                formula_number: '',
                scbt: '',
                pc: 0,
                no: '',
                product_time: '',
                slump: '',
                slump_name: '',
                measured_slump: 0,
                measured_slump_eva: '',
                measured_concrete: 0,
                measured_concrete_eva: '',
                place_name: '',
                remark: '',
                class_name: '',
                inspect_time: '',
                creator: '',
                created: '',
                plate_rates: [
                    {
                        name: '',
                        rate: 0,
                        val: 0,
                        m_val: 0,
                        m_rate: 0,
                        eva: '',
                    },
                ],
                car_rates: [
                    {
                        name: '',
                        rate: 0,
                        val: 0,
                        m_val: 0,
                        m_rate: 0,
                        eva: '',
                    },
                ],
            },
        };
    },
    created() {
        this.initData();
    },
    methods: {
        initData() {
            this.companyName = this.$takeTokenParameters('CompanyName');
            this.form.cir_id = this.extr.cir_id || '';
            this.form.task_number = this.extr.task_number || '';

            this.getData();
        },
        // 变更排车单编号
        changePcb(val) {
            for (const item of this.form.pcb_infos) {
                if (item.pcb_number === val) {
                    this.choosePcbInfo = item;
                    this.form.formula_number = item.formula_number;
                    this.form.mixstation_code = item.mixstaion_name;
                    this.form.scbt = item.scbt;
                    break;
                }
            }
            this.getPcListData();
        },
        // 变更盘次
        changePc(val) {
            for (const item of this.choosePcbInfo.pc_infos) {
                if (item.pc === val) {
                    this.form.consumes_id = item.consumes_id;
                    this.form.product_time = item.product_time;
                    break;
                }
            }
            this.getPcListData();
        },
        // 初始化计算结果
        initTestResult() {
            this.form.plate_rates.forEach(item => {
                this.testResult(item);
            });
            this.form.car_rates.forEach(item => {
                this.testResult(item);
            });
        },
        // 计算测试结果
        testResult(item) {
            if (!item.rate) {
                item.rate = 0;
            }
            if (Math.abs(item.rate) >= Math.abs(item.m_rate)) {
                item.eva = '合格';
            } else {
                item.eva = '不合格';
            }
        },
        // 实测坍落度值 结果
        testResultMeasured() {
            if (this.form.measured_slump === null || this.form.measured_slump === '') {
                return;
            }
            const diffVal = this.form.slump_name.split('±');
            const min = Number(diffVal[0]) - Number(diffVal[1]);
            const max = Number(diffVal[0]) + Number(diffVal[1]);
            if (this.form.measured_slump >= min && this.form.measured_slump <= max) {
                this.form.measured_slump_eva = '合格';
            } else {
                this.form.measured_slump_eva = '不合格';
            }
        },
        // 获取初始化数据
        getData() {
            this.formLoading = true;
            this.$axios.get(`/interfaceApi/production/concreteinspectrecord/init_data/${this.extr.task_number}/${this.extr.cir_id}`)
                .then(res => {
                    this.form = res;
                    for (const item of this.form.pcb_infos) {
                        if (item.pcb_number === this.form.pcb_number) {
                            // 初始盘次选项列表
                            this.choosePcbInfo = item;
                            break;
                        }
                    }
                    // 添加
                    if (!this.form.cir_id) {
                        this.form.created = utils.currentTimeFormat('yyyy-MM-dd');
                        this.form.creator = this.$takeTokenParameters('Name');
                        this.initTestResult();
                    }
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                }).finally(() => {
                    this.formLoading = false;
                });
        },
        // 根据盘次获取每盘、整车消耗
        getPcListData() {
            this.$axios.get('/interfaceApi/production/concreteinspectrecord/rate_data/'
                + `${this.form.pcb_number}/${this.form.mixstation_code}/${this.form.pc}`)
                .then(res => {
                    this.form.plate_rates = res.plate_rates || [];
                    this.form.car_rates = res.car_rates || [];
                    this.initTestResult();
                }).catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        // 保存数据
        saveData() {
            const valid = this.simpleValid();
            if (valid) {
                this.$axios.post('/interfaceApi/production/concreteinspectrecord/add_or_update', this.form)
                    .then(() => {
                        this.$message.success('保存成功');
                        this.$parent.hide();
                        window.updateTable();
                    }).catch(error => {
                        this.$message.error(error.ErrorCode.Message);
                    });
            }
        },
        // 简单校验
        simpleValid() {
            for (const item of this.form.plate_rates) {
                if (item.rate === null || item.rate === '' ) {
                    this.$message.warning('每盘用料称量计量:【' + item.name + '】每盘计量允许偏差值不能为空');
                    return false;
                }
            }
            for (const item of this.form.car_rates) {
                if (item.rate === null || item.rate === '' ) {
                    this.$message.warning('每一运输车用料称量计量:【' + item.name + '】每盘计量允许偏差值不能为空');
                    return false;
                }
            }
            if (!this.form.no) {
                this.$message.warning('编号不能为空');
                return false;
            }
            if (this.form.measured_slump === null || this.form.measured_slump === '' ) {
                this.$message.warning('实测坍落度值不能为空');
                return false;
            }
            if (this.form.measured_concrete === null || this.form.measured_concrete === '' ) {
                this.$message.warning('砼强度实测值不能为空');
                return false;
            }
            if (!this.form.measured_concrete_eva) {
                this.$message.warning('请选择砼强度实测值是否合格');
                return false;
            }
            if (!this.form.created) {
                this.$message.warning('请选择抽查日期');
                return false;
            }
            return true;
        },
        handleClose() {
            this.$parent.hide();
        },
    },
};
</script>

<style lang="stylus" scoped>
.calc-check-record{
    width 100%;
    height 100%;
    overflow auto
    padding 0.15rem
    padding-bottom 1rem
    font-size 0.16rem;
    color #333333
    .mini-input{
        width 0.7rem
        height 0.32rem
        line-height 0.32rem;
        text-align center
        border-radius: 0.02rem;
        border: 1px solid #E2EBF0;
    }
    .title{
        font-size 0.24rem;
        color #333;
        text-align center
    }
    .required:before{
        display inline-block;
        content '*'
        color red
        margin-right 0.03rem
    }
    .top-info{
        display flex
        justify-content space-between
        padding-bottom 0.07rem;
        color #002B94
        .el-input{
            width 1.6rem!important
            height 0.3rem
            >>> .el-input__inner{
                height 100%;
                border-radius 0
                border 0;
                border-bottom 1px solid #979EA7!important
                color #002B94
            }
        }
    }
    .table-info{
        width 100%;
        input{
            padding 0;
            -webkit-appearance: none !important;
        }
        input::-webkit-outer-spin-button,
              input::-webkit-inner-spin-button {
                  -webkit-appearance: none;
              }
        input[type="number"] {
            -moz-appearance: textfield;
        }
        >>> .el-input,>>> .el-textarea{
            textarea,input{
                padding-left 0
                border 0;
            }
        }

        table{
            width 100%
            table-layout fixed
            border 0
            border-collapse:collapse;
            th,td{
                margin 0
                padding 0.04rem 0.1rem
                border 1px solid #dedede;
                font-weight normal
            }
            th{
                color  #002B94;
                background #EDF0F5
            }
            td{
                text-align center
            }
            .col-merge{
                padding 0
            }
            .td-250{
                width 2.5rem
            }
            .work-place{
                height 1.05rem;
            }
        }
        .space-between{
            padding-right 0.1rem
            display flex
            justify-content space-between
            align-items center
        }
        .mid-input{
            width 1.2rem;
        }
        .icon-input{
            padding-left 0.3rem
            >>> textarea{
                border 0
            }
        }
        .table-wrapper{
            width 100%;
            display flex
            justify-content space-between
            .title-split{
                width 100%;
                height 0.65rem;
                display block
                position relative
                overflow hidden
                span{
                    display block
                    width 100%;
                    text-align left
                    padding: 5px;
                    padding-right 10px
                    &:nth-child(1){
                        text-align right
                    }
                }
                &:after{
                    display inline-block
                    content ''
                    width 150%
                    height 1px
                    background #D7D7D7
                    position absolute
                    left: -0.13rem;
                    top: 62%;
                    transform: rotate(26deg);
                }
            }
            >div{
                .wrapper-list{
                    max-height 3.3rem;
                    overflow-x hidden
                    overflow-y auto
                    &::-webkit-scrollbar {
                        width 0.06rem;
                        /*display: none;*/
                    }

                }
            }

        }
    }
    .bottom-info{
        width 100%;
        label{
            display inline-block
            width 33.3%;
            box-sizing border-box
            padding-left 0.1rem
            padding-top 0.1rem;
            color #002B94;
        }
        .el-input{
            width 1.6rem!important
            height 0.3rem
            >>> .el-input__inner{
                height 100%;
                border-radius 0
                border 0;
                border-bottom 1px solid #979EA7!important
            }
        }
    }
    .bottom-button{
        width 100%
        height 0.8rem;
        background #fff;
        display flex
        justify-content center
        padding .2rem 0
        position absolute
        bottom 0
        left 0
        button {
            width 2.5rem
            &:last-child{
                margin-left 1rem
            }
        }
    }

}
</style>
